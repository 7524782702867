import styled from "styled-components"


export const DotsList = styled.ul`
margin: 0;
padding: 0;
position: absolute;
left: 40%;
bottom: -50px;
li > button:before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: #8D8D8D;
  opacity: 0.55;
  } 
  li.slick-active > button:before {
    content: '';
    background-color: #F28E00;
    opacity: 1;
  }
`


export const NextArrow = styled.button`
background: transparent;
border: none;
color: #F28E00;
right: -10px;
z-index: 5000;
@media (min-width: 1070px) {
  right: 0px;
}
@media (min-width: 1410px) {
  right: -80px;
}
&::before {
  font-size: 1.6rem;
  color: #F28E00;
}

`
export const PrevArrow = styled.button`
background: transparent;
border: none;
color: #F28E00;
left: -10px;
z-index: 5000;
@media (min-width: 1070px) {
  left: -7px;
}
@media (min-width: 1410px) {
  left: -80px;
}
&::before {
  font-size: 1.6rem;
  color: #F28E00;
}
`

export const TeamNextArrow = styled.button`
background-color: transparent;
border: none;
color: #F28E00;
right: -10px;
z-index: 5000;
@media (min-width: 1070px) {
  right: 0px;
}
@media (min-width: 1410px) {
  right: 0px;
}
&::before {
  font-size: 1.6rem;
  color: #F28E00;
}
`
export const TeamPrevArrow = styled.button`
background-color: transparent;
border: none;
color: #F28E00;
left: -10px;
z-index: 5000;
@media (min-width: 1070px) {
  left: -7px;
}
@media (min-width: 1410px) {
  left: 0px;
}
&::before {
  font-size: 1.6rem;
  color: #F28E00;
}
`


export const PartnerNextArrow = styled.button`
background-color: transparent;
border: none;
color: #F28E00;
right: -10px;
z-index: 5000;
top: 0;
@media (min-width: 1070px) {
  right: -7px;
}
@media (min-width: 1410px) {
  right: -15px;
}
&::before {
  font-size: 1.6rem;
  color: #F28E00;
}
`
export const PartnerPrevArrow = styled.button`
background-color: transparent;
border: none;
color: #F28E00;
left: -10px;
z-index: 5000;
top: 0;
@media (min-width: 1070px) {
  left: -7px;
}
@media (min-width: 1410px) {
  left: -15px;
}
&::before {
  font-size: 1.6rem;
  color: #F28E00;
}
`