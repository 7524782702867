import React from 'react'
import styled from 'styled-components'

const mailIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15.074" height="11.16" viewBox="0 0 15.074 11.16">
<path id="Path_2802" data-name="Path 2802" d="M163.962,81H151.112A1.141,1.141,0,0,0,150,82.166v8.827a1.141,1.141,0,0,0,1.112,1.166h12.849a1.141,1.141,0,0,0,1.112-1.166V82.166A1.141,1.141,0,0,0,163.962,81Zm0,.93a.143.143,0,0,1,.1.05l-6.522,4.646-6.522-4.646a.143.143,0,0,1,.1-.05Zm0,9.3H151.112a.216.216,0,0,1-.17-.236V83.075l6.319,4.5a.454.454,0,0,0,.046.023.471.471,0,0,0,.043.022.482.482,0,0,0,.186.043h0a.482.482,0,0,0,.186-.043.463.463,0,0,0,.043-.022.451.451,0,0,0,.046-.023l6.319-4.5v7.919A.216.216,0,0,1,163.962,91.23Z" transform="translate(-150 -81)" fill="#1d1d1b"/>
</svg>

const phoneIcon = <svg xmlns="http://www.w3.org/2000/svg" width="15.279" height="15.279" viewBox="0 0 15.279 15.279">
<path id="Path_2801" data-name="Path 2801" d="M117.055,129.278a5.451,5.451,0,0,0,2.544-.962,30.23,30.23,0,0,0,8.721-8.721c1.012-1.68,1.226-2.829.638-3.416l-1.991-1.991c-.43-.431-1.125-.007-1.353.132a7.854,7.854,0,0,0-1.375,1.124c-.339.339-1.444,1.5-1.443,2.251a.657.657,0,0,0,.188.478l1,1c.191.191-.185,1.5-1.746,3.062s-2.869,1.939-3.062,1.746l-1-1a.659.659,0,0,0-.477-.187h0c-.748,0-1.911,1.1-2.25,1.443a7.854,7.854,0,0,0-1.124,1.375c-.139.229-.563.923-.132,1.353l1.991,1.992A1.179,1.179,0,0,0,117.055,129.278Zm9.353-14.3,1.875,1.874c.076.076.164.679-.781,2.249a29.32,29.32,0,0,1-8.4,8.4c-1.569.947-2.171.856-2.249.781l-1.874-1.875a7.031,7.031,0,0,1,2.633-2.633l.879.878c.915.916,2.928-.263,4.412-1.746s2.662-3.5,1.746-4.412l-.879-.879a7.031,7.031,0,0,1,2.633-2.633Zm-8.571,8.338h0Zm5.821-5.822h0Z" transform="translate(-114.003 -113.999)" fill="#1d1d1b"/>
</svg>



const TeamMemberInner = styled.div`
  display: flex;
  margin: 0 auto;
  flex-direction: column;
  h5 {
    font-size: 14px;
    text-align: start;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 10px;
  }
`
const MemberImage = styled.img`
  height: 140px;
  width: auto;
  object-fit: cover;
  @media (min-width: 425px) {
    height: 170px;
    width: auto;
  }
  @media (min-width: 585px) {
    height: 225px;
    width: auto;
  }
  @media (min-width: 720px) {
    height: 370px;
    width: auto;
  }
  @media (min-width: 1024px) {
    height: 330px;
    width: auto;
  }
`

const TeamMemberContact = styled.div`
  max-width: 400px; 
  display: flex;
  flex-direction: column; 
  text-align: start;
  text-transform: capitalize;
`

const MemberInfo = styled.span`
  align-items: baseline;
  text-transform: capitalize;
  font-size: 14px;
  line-height: 22px;
  color: #3C3C3B;
  margin: 0;
  padding: 0;
  font-weight: 400;
  &:first-child {
    font-weight: 500;
  }
`
const TeamMember = ({ src, alt, name, intro, role, mail, phone }) => (
    <TeamMemberInner>
      <MemberImage src={src} alt={alt} />
      <h5>{intro}</h5>
      <TeamMemberContact>
        <MemberInfo>{name}</MemberInfo>
        <MemberInfo>{role}</MemberInfo>
        <MemberInfo>{mailIcon}  {mail}</MemberInfo>
        <MemberInfo>{phoneIcon}  {phone}</MemberInfo>
      </TeamMemberContact>
    </TeamMemberInner>
)

export default TeamMember